import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import ReactS3Uploader from 'react-s3-uploader';
import { ProgressBar } from './ProgressBar';
import { UploadButton } from './UploadButton';

const t = key => I18n.t(`file_uploader.${key}`);

const bytesToMb = sizeInBytes => {
  return sizeInBytes / 1024 ** 2;
};

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
`;

const FileUploader = props => {
  const {
    id,
    inputRef,
    maxFileSizeInMb = I18n.t('config.max_attachment_size_in_mb'),
    onUpload = () => {},
  } = props;

  const [progressValue, setProgressValue] = useState(0);
  const [error, setError] = useState('');
  const [uploadPending, setUploadPending] = useState(false);

  const preprocess = useCallback((file, callback) => {
    setProgressValue(0);
    setUploadPending(true);

    if (bytesToMb(file.size) > maxFileSizeInMb) {
      const unitName = t('errors.too_large_file_unit');
      setError(`${t('errors.too_large_file')}${maxFileSizeInMb}${unitName}`);
    } else {
      setError('');
      callback(file);
    }
  }, []);
  const onFinish = useCallback(
    signedUrl => {
      setError('');
      setUploadPending(false);
      setProgressValue(0);
      onUpload(signedUrl);
    },
    [onUpload],
  );
  const onError = useCallback(() => {
    setError(t('errors.supported_extensions_info'));
  }, []);
  const scrubFilename = useCallback(filename => encodeURIComponent(filename.trim()), []);

  const errorOccurred = useMemo(() => error !== '', [error]);
  const showProgressBar = useMemo(() => uploadPending || errorOccurred, [
    uploadPending,
    errorOccurred,
  ]);

  return (
    <>
      <ReactS3Uploader
        id={id}
        signingUrl="/file_upload"
        signingUrlMethod="GET"
        preprocess={preprocess}
        onProgress={setProgressValue}
        onError={onError}
        onFinish={onFinish}
        autoUpload
        contentDisposition="attachment"
        scrubFilename={scrubFilename}
        hidden
        inputRef={inputRef}
        multiple
      />
      <ProgressBarContainer hidden={!showProgressBar}>
        <ProgressBar
          progressValue={progressValue}
          progressText={t('progress_text')}
          error={error}
        />
      </ProgressBarContainer>
      <div className={errorOccurred ? 'mt-2' : ''}>
        <UploadButton forInputId={id} hidden={showProgressBar && !errorOccurred} />
      </div>
    </>
  );
};

export default FileUploader;
