import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '../../../assets/styles/colors';
import ImageFileIcon from './ImageFileIcon';

const IconContainer = styled.div`
  padding: 6px 8px;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: 20%;
  display: flex;
`;

const Icon = styled.i`
  font-size: 14px;
  color: ${colors.listButtonFontColor};
`;

const imageExtensions = ['jpg', 'jpeg', 'png', 'heic'];

export const FileIcon = props => {
  const { url, extension, setIsHovered } = props;

  const isImage = useMemo(() => imageExtensions.includes(extension), [extension]);

  if (isImage) return <ImageFileIcon url={url} setIsHovered={setIsHovered} />;

  return (
    <IconContainer>
      <Icon className="fas fa-file" />
    </IconContainer>
  );
};
