import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  padding: 0.375rem 0.75rem;
`;

export const SmallLightDropdown = ({
  children,
  renderItems,
  disabled,
  additionalClasses,
  ...props
}) => (
  <div className="dropdown">
    <Button
      className={`btn btn-light btn-sm dropdown-toggle ${
        disabled ? 'disabled' : ''
      } ${additionalClasses}`}
      aria-expanded="false"
      aria-haspopup="true"
      data-toggle="dropdown"
      href="#"
      type="button"
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu">
      {renderItems()}
    </div>
  </div>
);
