import React, { useMemo } from 'react';
import styled from 'styled-components';
import { fontSize } from '../../../assets/styles/typography';
import { colors } from '../../../assets/styles/colors';

const minProgressValue = 0;
const maxProgressValue = 100;

const Container = styled.div`
  width: 100%;
  max-width: 500px;
`;

const Error = styled.div`
  font-size: ${fontSize.textSm};
  color: ${colors.error};
`;

export const ProgressBar = props => {
  const { progressText, progressValue, error } = props;

  const errorOccurred = useMemo(() => error !== '', [error]);

  const colorClass = useMemo(() => (errorOccurred ? 'bg-danger' : 'bg-info'), [errorOccurred]);
  const actualProgressValue = useMemo(() => (errorOccurred ? maxProgressValue : progressValue), [
    errorOccurred,
    progressValue,
  ]);
  const actualProgressText = useMemo(
    () => (errorOccurred ? I18n.t('file_uploader.errors.fail') : progressText),
    [errorOccurred, progressText],
  );

  return (
    <Container>
      <div className="progress">
        <div
          className={`progress-bar progress-bar-striped ${colorClass}`}
          role="progressbar"
          aria-valuemin={minProgressValue}
          aria-valuemax={maxProgressValue}
          aria-valuenow={actualProgressValue}
          style={{
            width: actualProgressValue.toString().concat('%'),
          }}
        >
          {actualProgressText}
        </div>
      </div>
      <Error hidden={!errorOccurred}>{error}</Error>
    </Container>
  );
};
