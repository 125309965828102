/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { reduce } from 'lodash';
import { API } from '../../services/api';
import { Loader } from '../Loader';
import { SmallLightDropdown } from '../buttons/SmallLightDropdown';

const t = (key, plural = false) => `${I18n.t(`activerecord.models.${key}`)}${plural ? 's' : ''}`;

const InvestigableRelatedItems = ({ investigable: { type, id, identifier } }) => {
  const [fetching, setFetching] = useState(true);
  const [relatedItems, setRelatedItems] = useState({});
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    (async () => {
      const { ok, data } = await API.investigables.relatedItems(id, type);
      if (ok) {
        setRelatedItems(data);
        setVisible(
          Object.values(data)
            .map(item => item.enabled)
            .includes(true),
        );
        setFetching(false);
      }
    })();
  }, []);

  const newPath = (path, parameter) =>
    `${path}/new?investigable_type=${type}&investigable_id=${id}${parameter || ''}`;

  const newRelatedItemsConfig = [
    { resource: 'assessment', path: newPath('/ehs_assessments', '&internal=true') },
    { resource: 'finding', path: newPath('/internal_findings', '&sub_type=internal') },
    { resource: 'violation', path: newPath('/violations') },
    { resource: 'external_inspection', path: newPath('/inspections', '&internal=false') },
    { resource: 'accident', path: newPath('/occurrences/accidents') },
  ].filter(item => Object.keys(relatedItems).includes(item.resource));

  const indexPath = (path, parameter) =>
    `${path}?filterrific[with_investigable_id][]=${identifier}&filterrific[with_investigable_type][]=${type}${parameter ||
      ''}`;

  const relatedItemsConfig = [
    { resource: 'assessment', path: indexPath('/ehs_assessments', '&internal=true') },
    { resource: 'finding', path: indexPath('/internal_findings', '&sub_type=internal') },
    { resource: 'violation', path: indexPath('/violations') },
    { resource: 'external_inspection', path: indexPath('/inspections', '&internal=false') },
    { resource: 'external_finding', path: indexPath('/external_findings', '&sub_type=external') },
    { resource: 'accident', path: indexPath('/occurrences/accidents') },
  ].filter(item => Object.keys(relatedItems).includes(item.resource));

  const renderBadge = count => (
    <span className={`badge badge-pill ${count === 0 ? 'badge-secondary' : 'badge-danger'} ml-1`}>
      {count}
    </span>
  );

  const totalRelatedItemsCount = () =>
    reduce(
      relatedItemsConfig,
      (sum, { resource }) =>
        relatedItems[resource].enabled ? sum + relatedItems[resource].count : sum,
      0,
    );

  const Link = ({ path, children, badgeCount }) => (
    <a
      href={path}
      target="_blank"
      className="dropdown-item d-flex justify-content-between align-items-center"
      rel="noreferrer"
    >
      {children}
      {badgeCount !== undefined && renderBadge(badgeCount)}
    </a>
  );

  const renderRelatedItems = () =>
    relatedItemsConfig.map(({ resource, path }) => (
      <Link path={path} badgeCount={relatedItems[resource].count}>
        {t(resource, true)}
      </Link>
    ));

  const renderNewRelatedItems = () =>
    newRelatedItemsConfig.map(({ resource, path }) => <Link path={path}>{t(resource)}</Link>);

  return fetching ? (
    <div className="d-flex align-items-center">
      <Loader />
    </div>
  ) : (
    visible && (
      <div className="d-flex">
        <SmallLightDropdown renderItems={renderRelatedItems} additionalClasses="mr-3">
          {I18n.t('form.header.view_investigable_related')}
          {renderBadge(totalRelatedItemsCount())}
        </SmallLightDropdown>
        <SmallLightDropdown renderItems={renderNewRelatedItems}>
          {I18n.t('form.header.add_investigable_related')}
        </SmallLightDropdown>
      </div>
    )
  );
};

export default InvestigableRelatedItems;
