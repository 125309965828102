import styled from 'styled-components';
import { fontSize } from '../../assets/styles/typography';
import { colors } from '../../assets/styles/colors';

export const TextArea = styled.textarea`
  display: block;
  font-size: ${fontSize.textMd};
  color: ${colors.inputTextColor};
  width: 100%;
  padding: 8px;
  background: ${props => (props.disabled ? colors.disabled : colors.white)};
  background-clip: padding-box;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: 4px;
  &:focus {
    border-color: ${colors.outline};
    border-width: 1;
    box-shadow: 0.005em 0.005em 0.005em ${colors.outline} inset,
      0.005em 0.005em 0.005em ${colors.outline};
    outline: 0;
  }
`;
