import React, { useMemo } from 'react';
import { api } from '../../../services/api';
import { Comments } from './Comments';

const produceApiFunctions = (url, commentableRole = 'general') => ({
  index: () => api.get(url, { commentable_role: commentableRole }),
  create: (comment, attachments = []) =>
    api.post(url, {
      comment: { body: comment, attachments, commentable_role: commentableRole },
    }),
});

// This is a temporary soution to allow HAML to use the Comments component.
// It should be removed once the HAML is converted to React.
const CommentsHamlProxy = props => {
  const { id, api_base, role, disabled } = props;

  const url = useMemo(() => `${api_base}/${id}/comments`, [api_base, id]);
  const apiFunctions = useMemo(() => produceApiFunctions(url, role), [url, role]);

  return (
    <>
      <Comments api={apiFunctions} disabled={disabled} />
    </>
  );
};

export default CommentsHamlProxy;
