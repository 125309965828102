import React from 'react';
import styled from 'styled-components';
import { SmallLightButton } from '../../buttons/SmallLightButton';

// Used line-height !important to ensure proper display on HAML forms.
// Can be removed once all forms are in React
const StyledLabel = styled.label`
  cursor: pointer;
  margin: 0;
  line-height: initial !important;
`;

const StyledButtonContent = styled.span`
  padding-left: 10px;
`;

export const UploadButton = props => {
  const { forInputId, hidden } = props;
  return (
    <SmallLightButton type="button" hidden={hidden}>
      <StyledLabel htmlFor={forInputId}>
        <i className="fa fa-upload" />
        <StyledButtonContent>{I18n.t('file_uploader.upload_file')}</StyledButtonContent>
      </StyledLabel>
    </SmallLightButton>
  );
};
