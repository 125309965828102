import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../../modals/Modal';

const ImagePreview = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 20%;
  cursor: pointer;
  &:hover {
    opacity: 50%;
  }
`;

const ImageFileIcon = props => {
  const { url, setIsHovered } = props;

  const modalRef = useRef(null);

  const [naturalWidth, setNaturalWidth] = useState(null);

  return (
    <>
      <ImagePreview
        src={url}
        alt="attachment"
        onClick={() => {
          if (!naturalWidth) return;

          // without this, the hover effect will remain after closing the modal
          // (links will be visible)
          setIsHovered(false);
          modalRef.current.open();
        }}
        onLoad={({ target }) => {
          setNaturalWidth(target?.naturalWidth);
        }}
      />
      <Modal ref={modalRef} wrapperStyle={{ width: naturalWidth }}>
        {() => <img src={url} alt="attachment" style={{ maxWidth: 'inherit' }} />}
      </Modal>
    </>
  );
};

export default ImageFileIcon;
