import React, { useMemo, useState } from 'react';
import last from 'lodash/last';
import styled from 'styled-components';
import { FileIcon } from './FileIcon';
import { colors } from '../../../assets/styles/colors';

const DownloadLink = styled.a`
  color: ${colors.download};
`;

const RemovalLink = styled.a`
  color: ${colors.delete};
  cursor: pointer;
`;

const LinksWrapper = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  vertical-align: middle;
`;

const AttachmentPresenter = props => {
  const { url, marginClass, onRemove } = props;

  const [isHovered, setIsHovered] = useState(false);

  const filename = useMemo(() => decodeURIComponent(last(new URL(url)?.pathname.split('/'))), [
    url,
  ]);
  const extension = useMemo(() => last(filename.split('.')), [filename]);

  return (
    <div
      className={`d-flex flex-wrap align-items-center ${marginClass}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="d-flex align-items-center">
        <FileIcon url={url} extension={extension} setIsHovered={setIsHovered} />
        <div className="ml-2 overflow-hidden">{filename}</div>
      </div>
      <LinksWrapper visible={isHovered}>
        <DownloadLink className="ml-2" download={filename} href={url}>
          {I18n.t('comments.download_attachment')}
        </DownloadLink>
        {onRemove && (
          <RemovalLink className="ml-2" onClick={onRemove}>
            {I18n.t('comments.remove_attachment')}
          </RemovalLink>
        )}
      </LinksWrapper>
    </div>
  );
};

export default AttachmentPresenter;
