import React, { PureComponent } from 'react';
import { Loader } from '../../Loader';
import { FieldWrapper } from '../../fields/auxiliary/FieldWrapper';
import { UserIcon } from './UserIcon';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { Comment } from './Comment';
import { SmallBrandButton } from '../../buttons/SmallBrandButton';
import { TextArea } from '../../inputs/TextArea';
import { showBackendErrorMessage } from '../../../services/utils';
import FileUploader from '../fileUploader';
import AttachmentPresenter from './AttachmentPresenter';

export class Comments extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      comments: [],
      newComment: '',
      newAttachments: [],
    };
  }

  componentDidMount() {
    this.fetchComments();
  }

  fetchComments = async () => {
    const { api } = this.props;
    const {
      ok,
      data: { data },
    } = await api.index();
    if (ok) {
      this.setState({ fetching: false, comments: data });
    }
  };

  addComment = () => {
    const { api } = this.props;
    const { newComment, newAttachments } = this.state;
    this.setState({ fetching: true }, async () => {
      const { ok, data } = await api.create(newComment, newAttachments);
      if (!ok) {
        showBackendErrorMessage(I18n.t('comments.add_error'), data);
      }
      this.setState({ newComment: '', newAttachments: [] }, this.fetchComments);
    });
  };

  addAttachment = signedUrl => {
    const { newAttachments } = this.state;
    const url = signedUrl?.signedUrl?.split('?')[0];

    if (!url) return;

    this.setState({
      newAttachments: [...newAttachments, { url }],
    });
  };

  removeAttachment = index => {
    const { newAttachments } = this.state;
    this.setState({
      newAttachments: newAttachments.filter((_, i) => i !== index),
    });
  };

  render() {
    const { displayMode = fieldDisplayModes.WIDE_FORM_ROW, disabled, resourceName } = this.props;
    const { fetching, comments, newComment, newAttachments } = this.state;

    const anyAttachments = newAttachments.length > 0;

    return (
      <FieldWrapper displayMode={displayMode}>
        {fetching && <Loader />}
        {!fetching && (
          <div className="w-100">
            {comments.map(({ id, attributes }) => (
              <div className="w-100 d-flex" key={`comment-${id}`}>
                <UserIcon />
                <Comment id={`${resourceName}-comment-${id}`} attributes={attributes} />
              </div>
            ))}
            {!disabled && (
              <>
                <div className="d-flex w-100">
                  <UserIcon />
                  <div className="w-100 ml-3">
                    <TextArea
                      id={`${resourceName}-add-comment-input`}
                      rows={3}
                      placeholder={I18n.t('comments.add')}
                      value={newComment}
                      onChange={e => this.setState({ newComment: e.target.value })}
                      onKeyDown={e => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          this.addComment();
                        }
                      }}
                    />
                    <div>
                      <div>
                        {newAttachments.map((attachment, index) => (
                          <AttachmentPresenter
                            key={attachment.url}
                            marginClass="mt-2"
                            onRemove={() => this.removeAttachment(index)}
                            {...attachment}
                          />
                        ))}
                      </div>
                      <div className={`d-flex align-middle ${anyAttachments ? 'mt-3' : 'mt-2'}`}>
                        <div>
                          <SmallBrandButton
                            id={`${resourceName}-add-comment-button`}
                            disabled={newComment.trim().length === 0}
                            onClick={this.addComment}
                            additionalClasses="text-nowrap"
                          >
                            {I18n.t('comments.add')}
                          </SmallBrandButton>
                        </div>
                        <div className="w-100 ml-2">
                          <FileUploader
                            id={`${resourceName}-add-comment-attachments`}
                            onUpload={this.addAttachment}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </FieldWrapper>
    );
  }
}
