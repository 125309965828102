import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../assets/styles/colors';
import { dateFormatter } from '../../../services/dateFormatUtils';
import AttachmentPresenter from './AttachmentPresenter';

const Container = styled.div`
  overflow: hidden;
`;

const UserName = styled.span`
  font-weight: bold;
`;

const CreatedDate = styled.span`
  padding-left: 5px;
  color: ${colors.gray};
`;

const Body = styled.div`
  white-space: pre-wrap;
`;

export const Comment = ({
  id,
  attributes: {
    user: { first_name, last_name },
    created_at,
    body,
    attachments,
  },
}) => (
  <Container id={id} className="ml-3 mb-4 w-100">
    <div>
      <UserName>{`${first_name} ${last_name}`}</UserName>
      <CreatedDate>{dateFormatter.fromNow(created_at)}</CreatedDate>
    </div>
    <Body>{body}</Body>
    <div>
      {attachments.map(attachment => (
        <AttachmentPresenter key={attachment.url} marginClass="mt-2" {...attachment} />
      ))}
    </div>
  </Container>
);
