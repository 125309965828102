import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  padding: 0.375rem 0.75rem;
`;

const produceOnClick = onClick => e => {
  if (!onClick) return;

  e.preventDefault();
  onClick(e);
};

export const SmallBrandButton = ({
  children,
  onClick,
  disabled,
  additionalClasses = '',
  ...props
}) => (
  <Button
    type="button"
    className={`btn btn-brand btn-sm ${disabled ? 'disabled' : ''} ${additionalClasses}`}
    onClick={produceOnClick(onClick)}
    disabled={disabled}
    {...props}
  >
    {children}
  </Button>
);
