import React, { Component } from 'react';
import cloneDeep from 'lodash/fp/cloneDeep';
import MonitorChecklist from './MonitorChecklist';
import { API } from '../../../services/api';
import { SaluteOrganizationContext } from '../../../stateless_components/contexts/SaluteOrganization';
import Comments from '../../Comments';
import MonitorsSelector from './MonitorsSelector';
import { PrimaryButton } from '../../../stateless_components/buttons/PrimaryButton';
import { DeletionLink } from '../../../stateless_components/buttons/DeletionLink';

const translate = key => I18n.t(`fire_drill.fire_drill_location_monitors.${key}`);

class MonitorsChecklistWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      fetchQuestions: true,
      saving: false,
      activeRelatedEntity: '',
    };
  }

  fetchChecklist = () => {
    this.setState({ fetchQuestions: true }, () => {
      this.updateChecklist();
    });
  };

  updateChecklist = () => {
    const { fire_drill_location_id, fire_drill_id, id } = this.props.params;
    API.monitorChecklistQuestion
      .questionAnswers(fire_drill_id, fire_drill_location_id, id)
      .then(response => {
        this.setState({
          items: response.data.data.map(item => item.attributes),
          fetchQuestions: false,
        });
      });
  };

  saveAnswers = () => {
    this.setState({ saving: true }, () => {
      const { fire_drill_location_id, fire_drill_id, id } = this.props.params;
      const { items } = this.state;
      API.monitorChecklistQuestion
        .setAnswers(fire_drill_location_id, fire_drill_id, id, items)
        .then(response => {
          if (response.ok) {
            window.flashMessage(translate('success_flash_message.save_checklist'));
            this.setState({ saving: false });
          } else {
            window.flashMessage(translate('error_flash_message.save_checklist'), true, 'error');
            this.setState({ saving: false });
          }
        });
    });
  };

  setAnswer = (id, answer) => {
    const { items } = this.state;

    this.setState({
      items: items.map(item => {
        if (item.id === id) {
          return {
            ...item,
            answer,
            dirty: true,
          };
        }
        return item;
      }),
    });
  };

  changeCommentAndFiles = (id, relevantItem) => {
    const { items } = this.state;

    this.setState({
      items: items.map(item => {
        if (item.id === id) {
          return {
            ...item,
            comment: relevantItem.comment,
            attachments: relevantItem.attachments,
            links: relevantItem.links,
          };
        }
        return item;
      }),
    });
  };

  saveCommentAndFile = (questionID, comment, attachments, links) => {
    const { fire_drill_location_id, fire_drill_id, id } = this.props.params;
    const { items } = this.state;
    const relevantItem = cloneDeep(items.find(item => item.id === questionID));
    relevantItem.comment = comment;
    relevantItem.attachments = attachments;
    relevantItem.links = links;
    return API.monitorChecklistQuestion
      .saveCommentAndFile(fire_drill_location_id, fire_drill_id, id, questionID, relevantItem)
      .then(response => {
        if (response.ok) {
          window.flashMessage(translate('success_flash_message.save_checklist'));
          this.changeCommentAndFiles(questionID, relevantItem);
        } else {
          window.flashMessage(
            translate('error_flash_message.save_comments_and_file'),
            true,
            'error',
          );
        }
        return response;
      });
  };

  deleteMonitor = () => {
    const { fire_drill_location_id, fire_drill_id, id } = this.props.params;
    return API.fireDrillLocationMonitor
      .destroy(fire_drill_id, fire_drill_location_id, id)
      .then(response => {
        if (response.ok) {
          sessionStorage.setItem('flashMessage', translate('success_flash_message.delete'));
          window.location.href = `/fire_drills/${fire_drill_id}/fire_drill_locations/${fire_drill_location_id}/edit`;
        } else {
          window.flashMessage(response.data.message, true, 'error');
        }
        return response;
      });
  };

  isExplanationMissing = item => {
    const noAnswerCode = 2;
    return (
      item.answer_type === 'yes_no_na' &&
      parseInt(item.answer, 10) === noAnswerCode &&
      (item.comment === '' || item.comment === null) &&
      item.attachments.length === 0 &&
      item.links.length === 0
    );
  };

  checkRequiredExplanations = items => {
    let missingRequiredExplanation = false;
    items.forEach(item => {
      if (this.isExplanationMissing(item)) {
        missingRequiredExplanation = true;
      }
    });
    return missingRequiredExplanation;
  };

  saveAnswersBtnBody = missingRequiredExplanations => {
    if (missingRequiredExplanations) {
      return (
        <div data-toggle="tooltip" title={translate('buttons.save_tooltip')}>
          {translate('buttons.save')}
        </div>
      );
    }
    return translate('buttons.save');
  };

  render() {
    const { saving, items, fetchQuestions, activeRelatedEntity } = this.state;
    const {
      params,
      disabled,
      current_organization,
      people_groups_count,
      fire_drill_people_group_id,
      internal,
      enums,
      custom_investigable_types_names,
    } = this.props;
    const { Provider } = SaluteOrganizationContext;
    const FindingsModuleProps = {
      setActiveRelatedEntity: newValue => this.setState({ activeRelatedEntity: newValue }),
      activeRelatedEntity,
      refreshList: this.updateChecklist,
      adminAccess: this.props.admin_access,
      peopleGroupsCount: people_groups_count,
      findingStatuses: enums.inspection_finding_status,
      customInvestigableTypesNames: custom_investigable_types_names,
      defaults: {
        creator_id: params.id,
        fire_drill_question_answer_id: activeRelatedEntity.id,
        people_group_id: fire_drill_people_group_id,
        internal,
      },
    };
    const missingRequiredExplanations = this.checkRequiredExplanations(items);

    return (
      <Provider value={current_organization.short_name}>
        <div className="container_form pt-1">
          <div className="mt-3 mb-3">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h2 className="no-line_height mb-3">{translate('form_sections.overview')}</h2>
            </div>
            <MonitorsSelector
              value={params.id}
              locationID={params.fire_drill_location_id}
              label="Monitor:"
              disabled
            />
          </div>
        </div>
        <div className="container_form pt-1">
          <div className="mt-3 mb-3">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h2 className="no-line_height mb-3">{translate('form_sections.observations')}</h2>
            </div>
          </div>
          {params.id && (
            <MonitorChecklist
              activeMonitor={params.id}
              fetchChecklist={this.fetchChecklist}
              setAnswer={this.setAnswer}
              items={items}
              fetching={fetchQuestions}
              saveCommentAndFile={this.saveCommentAndFile}
              findingProps={FindingsModuleProps}
              disabled={disabled}
              {...params}
            />
          )}
        </div>
        <div className="container_form pt-1">
          <div className="mt-3 mb-3">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h2 className="no-line_height mb-3">{translate('form_sections.comments')}</h2>
            </div>
          </div>
          {params.id && !this.state.fetchQuestions && (
            <Comments
              id={params.id}
              api_base={`/fire_drills/${params.fire_drill_id}/fire_drill_locations/${params.fire_drill_location_id}/fire_drill_location_monitors`}
              /* eslint-disable-next-line jsx-a11y/aria-role */
              role="general"
            />
          )}
        </div>
        <div className="d-flex flex-wrap align-items-center justify-content-end mt-3 mb-3">
          <div className="d-flex justify-content-start mr-auto">
            <DeletionLink modelName="Monitor" onDelete={this.deleteMonitor} disabled={!params.id}>
              {translate('buttons.delete')}
            </DeletionLink>
          </div>
          {!disabled && (
            <PrimaryButton
              className="ml-sm-3"
              disabled={saving || missingRequiredExplanations}
              onPress={this.saveAnswers}
              isPending={saving}
              noFadeIn
            >
              {this.saveAnswersBtnBody(missingRequiredExplanations)}
            </PrimaryButton>
          )}
        </div>
      </Provider>
    );
  }
}

export default MonitorsChecklistWrapper;
